import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import Logo from "../assets/images/logoNew.jpeg"
import helpers from "../Utils/helpers";

const Footer = () => {
  const navigate = useNavigate();
  let {
    user,
    setGameFooter,
    gameFooter,
    launchCasino,
    setParly,
    parly,
    parlyBet,
    setShowParlyBet,
  } = useContext(AuthProvider);
  const [select, setSelect] = useState("home");
  const location = useLocation();
  const handleSelect = (p) => {
    setSelect(p);
  };

  return (
    <div>
      {gameFooter && (
        <div className="lobby" data-v-2a94eb60="" data-v-5f5b6ef7="">
          <div className="gmnheader" data-v-302cf84b="" data-v-2a94eb60="">
            <div className="header__img" data-v-302cf84b="">
              <div className="header__img__title" data-v-302cf84b="">
                <picture
                  className="pictureClass"
                  data-v-28fc9a52=""
                  data-v-302cf84b=""
                >
                  <img
                    className="icon-promote"
                    src={
                      process.env.REACT_APP_MOBILE_URL +
                      "/assets/images/icon-title.webp"
                    }
                    alt=""
                    // src={process.env.REACT_APP_MOBILE_URL + '/yourPathHere.jpg' }
                  />
                </picture>
                <span className="header__number" data-v-302cf84b="">
                  2
                </span>
              </div>
            </div>

            <div className="header__close" data-v-302cf84b="">
              <img
                className="icon-promote"
                src={
                  process.env.REACT_APP_MOBILE_URL +
                  "/assets/images/icon-close.webp"
                }
                onClick={() => setGameFooter(false)}
                alt=""
                // src={process.env.REACT_APP_MOBILE_URL + '/yourPathHere.jpg' }
              />
            </div>
          </div>
        </div>
      )}
     <nav className="cls-footer-sec sk-footer">
        <ul
          className=""
          style={{ display: "flex", justifyContent: "space-between" }}
        >
           <li id="sports" className={select == "sports" ? "active" : ""}>
            <Link
              to="/sports"
              neua="Sports"
              className={select == "sports" ? "ui-link active" : "ui-link"}
              onClick={() => {handleSelect("sports");setParly(false);}}
            >
              <img
                className="icon-sports"
                src={
                  process.env.REACT_APP_MOBILE_URL +
                  "/assets/images/home/transparent.gif"
                }
                alt=""
                
              />
              Sports
            </Link>
          </li>
          <li id="inPlay" className={select == "inplay" ? "active" : ""}>
           
           <Link
             to="/inplay"
             neua="In-Play"
             className={select == "inplay" ? "ui-link active" : "ui-link"}
             onClick={() => {handleSelect("inplay");setParly(false);}}
           >
             <img
               className="icon-inplay"
               src={
                 process.env.REACT_APP_MOBILE_URL +
                 "/assets/images/home/transparent.gif"
               }
               alt=""
             />
             In-Play
           </Link>
    
         </li>
          

         <li id="inPlay" className={select == "multi" ? "active" : ""}>
           
           <Link
             to="/inplay"
             neua="In-Play"
             className={select == "multi" ? "ui-link active" : "ui-link"}
             onClick={() => {handleSelect("multi");setParly(true);}}
           >
            <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 1024 1024"
                height="2em"
                width="2em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M832 64H192c-17.7 0-32 14.3-32 32v224h704V96c0-17.7-14.3-32-32-32zM288 232c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zM160 928c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V704H160v224zm128-136c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zM160 640h704V384H160v256zm128-168c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40z"></path>
              </svg>
              Multi Bet
           </Link>
    
         </li>
         <li id="inPlay" >
           
           {/* <a target="_self" href={process.env.REACT_APP_WEB_URL}>
               <img
                 className="icon-home ui-link"
                 src={
                   process.env.REACT_APP_MOBILE_URL +
                   "/assets/images/home/transparent.gif"
                 }
                 alt=""
               />
                Main Home
             </a> */}
      
           </li>
         <li id="inPlay" >
           
         <a target="_self"  href={"https://"+helpers?.getDomain()}>
             <img
               className="icon-home ui-link"
               src={
                 process.env.REACT_APP_MOBILE_URL +
                 "/assets/images/home/transparent.gif"
               }
               alt=""
             />
              Main Home
           </a>
    
         </li>

        
        </ul>
      </nav>
      {["match-details", "inplay"]?.includes(
        location?.pathname?.split("/")[1]
      ) &&
        parly &&
        !isEmpty(user) &&
        parlyBet?.length > 0 && (
          <div
            onClick={() => {
              setShowParlyBet(true);
            }}
            className="betbtn1 betSlipBtn"
          >
            <img
              src={Logo}
              className="apana-icon"
              alt=""
            />
            <span>{parlyBet?.length}</span>
          </div>
        )}
    </div>
  );
};

export default Footer;
